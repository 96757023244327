import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step path`}</strong>{` -- print the configured step path and exit`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step path [--base] [--profile]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step path`}</strong>{` command prints the configured step path and exits.`}</p>
    <p>{`When using contexts to manage 'step-ca' environments, this command will return
the current authority path. If no current context is configured this command the
default step path of $HOME/.step, which can be overridden with the `}<strong parentName="p">{`STEPPATH`}</strong>{`
environment variable.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--base`}</strong>{`
Return the base of the step path`}</p>
    <p><strong parentName="p">{`--profile`}</strong>{`
Return the base path of the currently configured default profile`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Get the path with no current context configured:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step path
/Users/max/.step
`}</code></pre>
    <p>{`Get the path with no current context and environment variable STEPPATH overriding the default:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ export STEPPATH=/tmp/step
$ step path
/tmp/step
`}</code></pre>
    <p>{`Get the path with a current context (configured at $STEPPATH/current-context.json):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat $(step path --base)/current-context.json
{"context": "machine.step-internal.net"}

$ step path
/Users/max/.step/authorities/machine.step-internal.net
`}</code></pre>
    <p>{`Get the base path:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step path --base
/Users/max/.step
`}</code></pre>
    <p>{`Get the base path with environment variable STEPPATH overriding the default:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ export STEPPATH=/tmp/step
$ step path --base
/tmp/step
`}</code></pre>
    <p>{`Get the path of the current profile:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat $(step path --base)/current-context.json
{"context": "ca.acme.net"}

$ cat $(step path --base)/contexts.json
{
  "ca.beta.net": {
        "profile": "beta-corp",
        "authority": "machine.beta.net"
    },
  "ca.acme.net": {
        "profile": "example-corp",
        "authority": "machine.acme.net"
    }

}
$ step path --profile
/Users/max/.step/profiles/beta-corp
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      